import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { SliceStatement } from "../../types/interfaces"

export default function Statement({ slice }: SliceStatement) {
  const { cite, statement } = slice.primary

  return (
    <motion.blockquote
      className="statement"
      initial={{ opacity: 0, x: -8 }}
      whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      <p>{statement.text}</p>
      <cite className="cite">{cite.text}</cite>
    </motion.blockquote>
  )
}

export const query = graphql`
  fragment PageDataBodyStatement on PrismicPageDataBodyStatement {
    slice_type
    slice_label
    primary {
      statement {
        text
      }
      cite {
        text
      }
    }
  }
`
