import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { SliceQuote } from "../../types/interfaces"

export default function Quote({ slice }: SliceQuote) {
  const words = slice.primary.quote.text.match(/\S+\s*/gi)

  const container = {
    hidden: {},
    show: {
      transition: {
        type: "spring",
        mass: 1,
        damping: 280,
        stiffness: 60,
        staggerChildren: 0.15,
      },
    },
  }

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  return (
    <motion.blockquote
      className="quote"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      {words?.map((word, index) => (
        <motion.span key={`${word}-${index}`} variants={item}>
          {word}
        </motion.span>
      ))}
    </motion.blockquote>
  )
}

export const query = graphql`
  fragment PageDataBodyQuote on PrismicPageDataBodyQuote {
    slice_type
    slice_label
    primary {
      quote {
        text
      }
    }
  }
`
