import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SinglePageProps } from "../types/interfaces"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const Page = ({ location, data }: SinglePageProps) => {
  const doc = data.prismicPage.data

  if (!doc) return null

  return (
    <Layout
      location={location}
      pageTitle={doc.page_title.text}
      slices={doc.body}
    />
  )
}

export function Head({ data }) {
  return <SEO title={data.prismicPage.data.page_title.text} />
}

export const query = graphql`
  query PageQuery($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      data {
        page_title {
          text
        }
        body {
          __typename
          ...PageDataBodyAfbeelding
          ...PageDataBodyQuote
          ...PageDataBodyIntro
          ...PageDataBodyLink
          ...PageDataBodySectie
          ...PageDataBodyStatement
          ...PageDataBodyTekst
          ...PageDataBodyBestuursleden
          ...PageDataBodyComiteAanbeveling
          ...PageDataBodySlider
        }
      }
    }
  }
`

export default withPrismicPreview(Page)
