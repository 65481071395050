import BoardMembers from "./boardMembers"
import Committee from "./committee"
import Image from "./image"
import Intro from "./intro"
import Links from "./links"
import Quote from "./quote"
import Section from "./section"
import Slider from "./slider"
import Statement from "./statement"
import Text from "./text"

export const components = {
  afbeelding: Image,
  bestuursleden: BoardMembers,
  comite_aanbeveling: Committee,
  intro: Intro,
  link: Links,
  quote: Quote,
  sectie: Section,
  slider: Slider,
  statement: Statement,
  tekst: Text,
}
