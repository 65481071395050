import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { SliceCommittee } from "../../types/interfaces"

export default function Committee({ slice }: SliceCommittee) {
  const members = slice.items

  return (
    <motion.ul
      className="committee"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      {members.map((member: any) => {
        const { name, role, quote, photo } =
          member.committee_member.document.data

        return (
          <li key={name}>
            {photo.localFile ? (
              <GatsbyImage
                image={photo.localFile.childImageSharp.gatsbyImageData}
                alt={name.text}
              />
            ) : (
              <img src={photo.url} />
            )}
            <div>
              <h2>{name.text}</h2>
              <span>{role.text}</span>
              <blockquote>{quote.text}</blockquote>
            </div>
          </li>
        )
      })}
    </motion.ul>
  )
}

export const query = graphql`
  fragment PageDataBodyComiteAanbeveling on PrismicPageDataBodyComiteAanbeveling {
    slice_type
    slice_label
    items {
      committee_member {
        document {
          ... on PrismicCommittee {
            data {
              name {
                text
              }
              quote {
                text
              }
              role {
                text
              }
              photo {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      width: 500
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
