import React from "react"
import { SliceZone } from "@prismicio/react"
import { motion } from "framer-motion"
import { components } from "./slices"
import Header from "./header"
import Footer from "./footer"
import PageHeader from "./pageHeader"

interface Props {
  pageTitle: string
  slices?: any[]
}

export default function Layout({ pageTitle, slices }: Props) {
  const slicesGrouped = slices?.reduce((accumulator, currentValue, index) => {
    const { slice_type } = currentValue

    // Create new section for first element
    if (index === 0) {
      accumulator.push([currentValue]) // Hier moeten we een array toevoegen met de currentValue, vandaar de []
    } else if (slice_type === "sectie") {
      // Als dit een sectie type is, maak dan een nieuwe array aan met de currentvalue erin, in de accumulator array
      accumulator.push([currentValue]) // Hier moeten we een array toevoegen met de currentValue, vandaar de []
    } else if (accumulator[accumulator.length - 1]) {
      // accumulator.length = length of main array, -1 to convert it to an array index of the last item in the array
      // Else, als het laatste item van de accumulator bestaat (dus niet leeg is) voegen we currentValue toe
      accumulator[accumulator.length - 1].push(currentValue) // hier voegen we toe aan bestaande array met index (accumulator.length - 1)
    }

    return accumulator
  }, [])

  return (
    <>
      <Header />
      <motion.main className="page">
        <PageHeader pageTitle={pageTitle} />
        {slicesGrouped?.map((slices, index) => (
          <motion.div
            className="section grid"
            key={index}
            animate={{ y: 0, opacity: 1, transition: { duration: 1 } }}
            initial={{ y: 16, opacity: 0 }}
          >
            <SliceZone slices={slices} components={components} />
          </motion.div>
        ))}
      </motion.main>
      <Footer />
    </>
  )
}
