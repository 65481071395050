import React from "react"
import { motion } from "framer-motion"

export default function PageHeader({ pageTitle }: any) {
  return (
    <div className="page-header grid">
      {pageTitle && (
        <motion.h1
          className="h1"
          animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          initial={{ opacity: 0, y: 16 }}
        >
          {pageTitle}
        </motion.h1>
      )}
    </div>
  )
}
