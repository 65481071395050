import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { SliceBoardMembers } from "../../types/interfaces"

export default function BoardMembers({ slice }: SliceBoardMembers) {
  const members = slice.items

  return (
    <motion.ul
      className="board-members"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      {members.map((member: any) => {
        const { name, role, photo } = member.board_member.document.data
        return (
          <li key={name}>
            {photo.localFile ? (
              <GatsbyImage
                image={photo.localFile.childImageSharp.gatsbyImageData}
                alt={name.text}
              />
            ) : (
              <img src={photo.url} />
            )}
            <h2>{name.text}</h2>
            <span>{role.text}</span>
          </li>
        )
      })}
    </motion.ul>
  )
}

export const query = graphql`
  fragment PageDataBodyBestuursleden on PrismicPageDataBodyBestuursleden {
    slice_type
    slice_label
    items {
      board_member {
        document {
          ... on PrismicBoardMember {
            data {
              name {
                text
              }
              role {
                text
              }
              photo {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 552
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
