import React from "react"

interface Props {
  title: string
}

export default function SEO({ title }: Props) {
  return (
    <>
      <title>{`${title} | Rust in de Reuring`}</title>
      <meta
        name="description"
        content="Stimuleren van initiatieven die leiden tot Rust midden in de Reuring"
      />
      <meta name="image" content="/logo.png" />
      <meta property="og:url" content="https://www.rustindereuring.nl" />
      <meta property="og:title" content="Rust in de Reuring" />
      <meta
        property="og:description"
        content="Stimuleren van initiatieven die leiden tot Rust midden in de Reuring"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Rust in de Reuring" />
      <meta
        name="twitter:description"
        content="Stimuleren van initiatieven die leiden tot Rust midden in de Reuring"
      />
      <meta name="twitter:image" content="/logo.png" />
    </>
  )
}
