import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import '@fontsource/barlow'
import '@fontsource/barlow-condensed'
import Index from './src/pages/index.tsx'
import Page from './src/pages/{prismicPage.uid}.tsx'
import { AppContextProvider } from './src/context/state.tsx'
import './src/styles/preloader.css'
import './src/styles/app.scss'

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <PrismicProvider
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            // eslint-disable-next-line global-require
            linkResolver: require('./src/utils/linkResolver.js').linkResolver,
            componentResolver: componentResolverFromMap({
              homepage: Index,
              page: Page,
            }),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </PrismicProvider>
  </AppContextProvider>
)
