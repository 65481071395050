import React, { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useAppContext } from "../context/state"
import Icon from "./../components/icon"

export default function Nav() {
  const navItems = [
    {
      name: "Homepage",
      url: "",
    },
    {
      name: "RUST in de Reuring Route Rotterdam",
      url: "rust-in-de-reuring-route-rotterdam",
    },
    {
      name: "Kunstwerk Groene Hilledijk",
      url: "groene-hilledijk",
    },
    {
      name: "RiR op terras Bakkerswinkel",
      url: "rust-in-de-reuring-op-terras-bakkerswinkel",
    },
    {
      name: "Kunstroute",
      url: "kunstroute",
    },
    {
      name: "Prijsvraag",
      url: "prijsvraag",
    },
    {
      name: "Stichting Rust in de Reuring",
      subnav: [
        {
          name: "Bestuur & Comité van aanbeveling",
          url: "bestuur-en-comite-van-aanbeveling",
        },
        {
          name: "Initiatiefnemer",
          url: "initiatiefnemer",
        },
      ],
    },
  ]

  const { navOpen, handleNav } = useAppContext()
  const [subnavOpen, setSubnavOpen] = useState(false)

  const handleSubnav = () => {
    setSubnavOpen(!subnavOpen)
  }

  const container = {
    hidden: { opacity: 0, display: "none" },
    show: {
      opacity: 1,
      display: "flex",
      transition: { duration: 1 },
    },
  }

  const containerList = {
    hidden: {},
    show: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.1,
      },
    },
  }

  const containerItem = {
    hidden: { opacity: 0, y: 8 },
    show: { opacity: 1, y: 0, transition: { duariont: 1 } },
  }

  const containerAddress = {
    hidden: { opacity: 0, y: 24 },
    show: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
  }

  return (
    <motion.nav
      variants={container}
      animate={navOpen ? "show" : "hidden"}
      className={navOpen ? "nav is-open" : "nav"}
    >
      <motion.ul variants={containerList}>
        {navItems.map((item, index) => {
          const { name, url } = item
          return !item.subnav ? (
            <motion.li key={index} variants={containerItem}>
              <Link
                to={`/${url}`}
                activeStyle={{ color: "white" }}
                onClick={handleNav}
              >
                {name}
              </Link>
            </motion.li>
          ) : (
            <motion.li key={index} variants={containerItem}>
              <button
                type="button"
                onClick={() => handleSubnav()}
                className={subnavOpen ? "is-open" : ""}
              >
                {name}
              </button>
              {subnavOpen && (
                <ul>
                  {item.subnav.map((subItem) => (
                    <li key={subItem.name}>
                      <Link
                        to={`/${subItem.url}`}
                        activeStyle={{ color: "white" }}
                        onClick={handleNav}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </motion.li>
          )
        })}
      </motion.ul>
      <motion.address className="address" variants={containerAddress}>
        <strong>Contact</strong>
        Wibo van Gennip
        <a href="mailto:info@rustindereuring.nl">info@rustindereuring.nl</a>
        <a href="tel:0621511393">0621511393</a>
        <a
          href="https://www.instagram.com/rust.in.de.reuring/"
          className="footer__instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="instagram" />
        </a>
        <Link to="/privacy" onClick={handleNav}>
          Privacyverklaring
        </Link>
      </motion.address>
    </motion.nav>
  )
}
