import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { motion } from "framer-motion"
import { SliceText } from "../../types/interfaces"

export default function Text({ slice }: SliceText) {
  return (
    <motion.div
      className="text"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      <PrismicRichText field={slice.primary.text.richText} />
    </motion.div>
  )
}

export const query = graphql`
  fragment PageDataBodyTekst on PrismicPageDataBodyTekst {
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
  }
`
