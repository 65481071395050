import React from "react"
import { graphql } from "gatsby"
import { PrismicText } from "@prismicio/react"
import { motion } from "framer-motion"
import { SliceIntro } from "../../types/interfaces"

export default function Intro({ slice }: SliceIntro) {
  return (
    <motion.div
      className="intro"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      <PrismicText field={slice.primary.intro.richText} />
    </motion.div>
  )
}

export const query = graphql`
  fragment PageDataBodyIntro on PrismicPageDataBodyIntro {
    slice_type
    slice_label
    primary {
      intro {
        richText
      }
    }
  }
`
