import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SinglePageProps } from "../types/interfaces"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const Index = ({ data }: SinglePageProps) => {
  const doc = data.prismicHomepage.data

  if (!doc) return null

  return (
    <Layout location="home" pageTitle={doc.page_title.text} slices={doc.body} />
  )
}

export function Head({ data }) {
  return <SEO title={data.prismicHomepage.data.page_title.text} />
}

export const query = graphql`
  query HomePageQuery {
    prismicHomepage {
      _previewable
      data {
        page_title {
          text
        }
        body {
          __typename
          ... on PrismicHomepageDataBodyAfbeelding {
            slice_type
            slice_label
            primary {
              image {
                url
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 2116
                      placeholder: BLURRED
                    )
                  }
                }
              }
              caption {
                text
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyQuote {
            slice_type
            slice_label
            primary {
              quote {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyIntro {
            slice_type
            slice_label
            primary {
              intro {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyLink {
            slice_type
            slice_label
            items {
              link_title
              link {
                link_type
                url
              }
            }
          }
          ... on PrismicHomepageDataBodySectie {
            slice_type
            slice_label
            primary {
              section_title {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyStatement {
            slice_type
            slice_label
            primary {
              statement {
                text
              }
              cite {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyTekst {
            slice_type
            slice_label
            primary {
              text {
                richText
              }
            }
          }

          ... on PrismicHomepageDataBodyBestuur {
            slice_type
            slice_label
            items {
              board_member {
                document {
                  ... on PrismicBoardMember {
                    data {
                      name {
                        text
                      }
                      role {
                        text
                      }
                      photo {
                        url
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED
                              width: 552
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyComite {
            slice_type
            slice_label
            items {
              committee_member {
                document {
                  ... on PrismicCommittee {
                    data {
                      name {
                        text
                      }
                      quote {
                        text
                      }
                      role {
                        text
                      }
                      photo {
                        url
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: FIXED
                              width: 500
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodySlider {
            slice_type
            slice_label
            items {
              slider_image {
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 2116
                      placeholder: BLURRED
                    )
                  }
                }
              }
              slide_caption {
                text
                richText
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Index)
