import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { motion } from "framer-motion"
import { SliceImage } from "../../types/interfaces"

export default function Image({ slice }: SliceImage) {
  const { image, caption } = slice.primary
  const { copyright } = image

  return (
    <motion.figure
      className="image"
      initial={{ opacity: 0, x: 8 }}
      whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      {image.localFile ? (
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt=""
        />
      ) : (
        <img src={image.url} />
      )}

      {caption && caption.text !== "" ? (
        <figcaption className="figcaption">
          <PrismicRichText field={caption?.richText} />
          {copyright && <span> - Foto: {copyright}</span>}
        </figcaption>
      ) : null}
    </motion.figure>
  )
}

export const query = graphql`
  fragment PageDataBodyAfbeelding on PrismicPageDataBodyAfbeelding {
    slice_type
    slice_label
    primary {
      image {
        url
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 2116
              placeholder: BLURRED
            )
          }
        }
      }
      caption {
        text
        richText
      }
    }
  }
`
