import React from "react"
import Arrow from "../images/arrow.inline.svg"
import ArrowRight from "../images/arrow-right.inline.svg"
import Goal from "../images/goal.inline.svg"
import Focus from "../images/focus.inline.svg"
import Instagram from "../images/instagram.inline.svg"

const retrieveSource = (type: string) => {
  let src = ""

  switch (type) {
    case "arrow":
      src = Arrow
      break
    case "arrow-right":
      src = ArrowRight
      break
    case "goal":
      src = Goal
      break
    case "focus":
      src = Focus
    case "instagram":
      src = Instagram
      break
    default:
      throw new Error("Invalid SVG name")
  }

  return src
}

interface Props {
  type: string
  fill?: string
}

export default function Icon(props: Props) {
  const SVG = retrieveSource(props.type)

  return (
    <i className="icon" {...props}>
      <SVG />
    </i>
  )
}
