import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { SliceSection } from "../../types/interfaces"

export default function Section({ slice }: SliceSection) {
  const { section_title } = slice.primary

  return (
    <motion.h2
      className="section-heading"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      {section_title.text}
    </motion.h2>
  )
}

export const query = graphql`
  fragment PageDataBodySectie on PrismicPageDataBodySectie {
    slice_type
    slice_label
    primary {
      section_title {
        text
      }
    }
  }
`
