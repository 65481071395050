import React from "react"
import { graphql } from "gatsby"
import SwiperCore, { Autoplay, EffectFade, Navigation } from "swiper"
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"
import { SliceSlider } from "../../types/interfaces"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/effect-fade"

// install Swiper components
SwiperCore.use([Autoplay, EffectFade, Navigation])

export default function Slider({ slice }: SliceSlider) {
  const { items } = slice

  return (
    <motion.figure
      className="slider"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      <Swiper
        autoplay={{ delay: 5000 }}
        speed={1000}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        navigation
        spaceBetween={0}
        slidesPerView={1}
      >
        {items.map((item, index) => {
          const { slider_image, slide_caption } = item

          return (
            <SwiperSlide key={index}>
              {slider_image.localFile ? (
                <GatsbyImage
                  image={slider_image.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                />
              ) : (
                <img src={slider_image.url} />
              )}
              {slide_caption?.text !== "" ? (
                <figcaption className="figcaption">
                  <PrismicRichText field={slide_caption?.richText} />
                </figcaption>
              ) : null}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </motion.figure>
  )
}

export const query = graphql`
  fragment PageDataBodySlider on PrismicPageDataBodySlider {
    slice_type
    slice_label
    items {
      slider_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 2116
              placeholder: BLURRED
            )
          }
        }
      }
      slide_caption {
        text
        richText
      }
    }
  }
`
