module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#0A0A0A","display":"standalone","icon":"src/images/favicon.png","name":"Rust in de Reuring","short_name":"Rust Reuring","start_url":"/","theme_color":"#0A0A0A","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f276cd3578b663386044cc32d19436ae"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"rustindereuring","accessToken":"MC5YZGZlbnhFQUFIVWlCZFpJ.aU_vv73vv70ZSl_vv70fFe-_ve-_ve-_ve-_ve-_ve-_vXUa77-977-9HT_vv73vv73vv70777-977-9fE_vv71a","promptForAccessToken":true,"apiEndpoint":"https://rustindereuring.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
