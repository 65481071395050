import React from "react"
import { motion } from "framer-motion"
import { useAppContext } from "../context/state"

export default function Hamburger() {
  const { navOpen, handleNav, preloader } = useAppContext()

  const logoVariant = {
    hidden: {
      opacity: 0,
      x: 16,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        delay: preloader ? 11 + 1 : 0,
        duration: 0.5,
      },
    },
  }

  return (
    <motion.button
      role="button"
      aria-label="Open the menu"
      onClick={handleNav}
      className={navOpen ? "hamburger is-open" : "hamburger"}
      variants={logoVariant}
      animate="show"
      initial={preloader ? "hidden" : "show"}
    >
      <span />
    </motion.button>
  )
}
