import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useAppContext } from "../context/state"
// @ts-ignore
import logo from "../images/logo.svg"

export default function Logo() {
  const { preloader } = useAppContext()

  const logoVariant = {
    hidden: {
      opacity: 0,
      y: -16,
      zIndex: 4,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: preloader ? 11 + 1 : 0,
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div
      variants={logoVariant}
      animate="show"
      initial={preloader ? "hidden" : "show"}
    >
      <Link className="logo" to="/">
        <img src={logo} alt="Rust in de Reuring logo" />
      </Link>
    </motion.div>
  )
}
