import React from "react"
import Icon from "./../components/icon"

export default function Footer() {
  return (
    <footer className="footer">
      <h1>Stichting Rust in de Reuring</h1>
      <a href="mailto:info@rustindereuring.nl">info@rustindereuring.nl</a>
      <a
        href="https://www.instagram.com/rust.in.de.reuring/"
        className="footer__instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon type="instagram" />
      </a>
    </footer>
  )
}
