import React, { useEffect } from "react"
import { useAppContext } from "../context/state"
import Hamburger from "./hamburger"
import Logo from "./logo"
import Nav from "./nav"

export default function Header() {
  const { navOpen } = useAppContext()

  useEffect(() => {
    if (navOpen) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "visible"
    }
  }, [navOpen])

  return (
    <header className={navOpen ? "header header--open" : "header"}>
      <Logo />
      <Hamburger />
      <Nav />
    </header>
  )
}
