import React from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
import Icon from "../icon"
import { SliceLink } from "../../types/interfaces"

export default function Links({ slice }: SliceLink) {
  const { items } = slice

  const link = items.map((field, index) => {
    const { link_type, url } = field.link
    if (link_type === "Media") {
      return (
        <a
          className="link"
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Icon type="arrow" />
          {field.link_title}
        </a>
      )
    }

    if (link_type === "Document") {
      return (
        <Link className="link" key={index} to={url}>
          {field.link_title}
        </Link>
      )
    }

    if (link_type === "Web") {
      return (
        <a
          className="link"
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {field.link_title}
        </a>
      )
    }
  })
  return (
    <motion.div
      className="links"
      initial={{ opacity: 0, y: 8 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      {link}
    </motion.div>
  )
}

export const query = graphql`
  fragment PageDataBodyLink on PrismicPageDataBodyLink {
    slice_type
    slice_label
    items {
      link_title
      link {
        link_type
        url
      }
    }
  }
`
