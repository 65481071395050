import React, { createContext, useContext, useEffect, useState } from "react"

const defaultAppContext: any = {
  navOpen: false,
  handleNav: () => {},
  preloader: null,
}

const AppContext = createContext(defaultAppContext)

export function AppContextProvider({ children }: any) {
  const isBrowser = typeof window !== "undefined"
  const isVisited = isBrowser
    ? !window.sessionStorage.getItem("preloader")
    : false

  const [preloader, setPreloader] = useState(isVisited)

  useEffect(() => {
    setPreloader(false)
  }, [isVisited])

  const [navOpen, setNavOpen] = useState(false)

  function handleNav() {
    setNavOpen(!navOpen)
  }

  return (
    <AppContext.Provider
      value={{
        handleNav,
        preloader,
        navOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
